import { UI_ONBOARDING_BASE_FRAGMENT } from '@/graphql/_Fragments/UiOnboarding/Base';
import { UI_ONBOARDING_WIDGET_BASE_FRAGMENT } from '@/graphql/_Fragments/UiOnboardingWidget/Base';

export const UI_ONBOARDING_WIDGET_FULL_FRAGMENT = `
  fragment uiOnboardingWidgetFullFragment on UiOnboardingWidget {
    uid
    schemaCode
    community {
      uid
    }
    type
    onboarding {
      ...uiOnboardingBaseFragment
    }
    parentWidget {
      ...uiOnboardingWidgetBaseFragment
    }
    subWidgets {
      ...uiOnboardingWidgetBaseFragment
    }
    payload
    displaySequence
    createdTime
    updatedTime
  }
  ${UI_ONBOARDING_BASE_FRAGMENT}
  ${UI_ONBOARDING_WIDGET_BASE_FRAGMENT}
`;
